import '@/styles/output.css';
import '@/styles/input.css';

import '@/styles/index.scss'; // 全局css
// import '@/assets/iconfont/iconfont.js';
import '@/assets/iconfont/iconfont.css';

import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueCompositionAPI from '@vue/composition-api';
import Router from 'vue-router';
Vue.use(Router).use(VueCompositionAPI);
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import elTableInfiniteScroll from 'el-table-infinite-scroll';
Vue.use(elTableInfiniteScroll);
// 引入echarts
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

// 引入axios
import axios from 'axios';
Vue.prototype.$axios = axios;

import VueClipBoard from 'vue-clipboard2';

Vue.use(VueClipBoard);

import App from './App.vue';
import router from './router';
import store from './store';
import './utils/dragDialog';

Vue.prototype.$bus = new Vue();
// youtube 视频组件
import VueYouTubeEmbed from 'vue-youtube-embed';
Vue.use(VueYouTubeEmbed);
import '@/utils/loading';
import '@/permission'; // 权限控制
// 加水印
import '@/utils/watermark.js';
import { toFixed, byteLimit } from '@/utils/directive';
// 使用sentry
// Sentry.init({
//   Vue,
//   dsn: "https://60e21ef9871b49faaf1b985d691003ca@o1036953.ingest.sentry.io/6004631",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "gads2.giikin.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
import {
  Button,
  NavBar,
  Picker,
  Field,
  Popup,
  Icon,
  Grid,
  GridItem,
  Cell,
  CellGroup,
  Empty,
  Form,
  Tab,
  Tabs,
  Search,
  Tag,
  Divider,
  RadioGroup,
  Radio,
  Switch,
  Dialog,
  Stepper,
  Toast,
} from 'vant';

Vue.use(Button)
  .use(NavBar)
  .use(Picker)
  .use(Field)
  .use(Popup)
  .use(Icon)
  .use(Grid)
  .use(GridItem)
  .use(Cell)
  .use(CellGroup)
  .use(Empty)
  .use(Form)
  .use(Tab)
  .use(Tabs)
  .use(Search)
  .use(Tag)
  .use(Divider)
  .use(RadioGroup)
  .use(Radio)
  .use(Switch)
  .use(Dialog)
  .use(Stepper)
  .use(Toast);

Vue.use(ElementUI);

Vue.config.productionTip = false;
// console.log = function () { };
Vue.directive('toFixed', toFixed);
Vue.directive('byteLimit', byteLimit);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
